import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';

export default function Opinions() {
  return (
    <Switch>
      <Route path="/opinions" component={List} exact />
    </Switch>
  );
}
