import GETTING_STARTED from './GETTING_STARTED.md';
import AUTHENTICATION from './AUTHENTICATION.md';
import CHECKINS from './CHECKINS.md';
import USERS from './USERS.md';
import VENUE_SUBSCRIPTIONS from './VENUE_SUBSCRIPTIONS.md';
import UPLOADS from './UPLOADS.md';
import PREFERENCES from './PREFERENCES.md';
import FEEDS from './FEEDS.md';
import NOTIFICATIONS from './NOTIFICATIONS.md';
import VENUES from './VENUES.md';
import CATEGORIES from './CATEGORIES.md';
import OPINIONS from './OPINIONS.md';
import POSTS from './POSTS.md';
import CITIES from './CITIES.md';
import SEARCH from './SEARCH.md';
import ADMIN from './ADMIN.md';
import FOLLOWERS from './FOLLOWERS.md';
import PROMOS from './PROMOS.md';
import INVITES from './INVITES.md';
import MEETUPS from './MEETUPS.md';
import CHATS from './CHATS.md';

export default {
  GETTING_STARTED,
  AUTHENTICATION,
  CHECKINS,
  VENUE_SUBSCRIPTIONS,
  PREFERENCES,
  FEEDS,
  NOTIFICATIONS,
  USERS,
  VENUES,
  CATEGORIES,
  POSTS,
  UPLOADS,
  CHATS,
  CITIES,
  SEARCH,
  ADMIN,
  FOLLOWERS,
  OPINIONS,
  PROMOS,
  INVITES,
  MEETUPS,
};

export { default as CATEGORIES } from './CATEGORIES.md';
