import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';

export default function UserReports() {
  return (
    <Switch>
      <Route path="/reports/users" component={List} exact />
    </Switch>
  );
}
