import React from 'react';
import { Header, Table, Loader, Button, Message, Confirm, Image } from 'semantic';
import screen from 'helpers/screen';
import { formatDateTime } from 'utils/date';
import { HelpTip } from 'components';
import { SearchProvider } from 'components/search';
import { request } from 'utils/api';
import Menu from './Menu';
import { Link } from 'react-router-dom';
import { API_URL } from 'utils/env';
import { truncateString } from 'utils/formatting';
import { User, Post } from 'components/Layout/utils';
@screen
export default class PostOverview extends React.Component {

  onDataNeeded = async () => {
    return await request({
      method: 'GET',
      path: `/1/posts/${this.props.post.id}/comments`,
    });
    };

  onParentDataNeeded = async () => {
    return await request({
      method: 'GET',
      path: `/1/posts/${this.props.post.parent}`
    });
  };

  render() {    
    const { post, reports } = this.props;
    const isComment = Boolean(post.parent);
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {!post ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>User</Table.Cell>
                  <Table.Cell>{post.author.name}</Table.Cell>
                </Table.Row>
                {post.parent && (
                  <Table.Row>
                  <Table.Cell>Parent</Table.Cell>
                  <Table.Cell> <Link to={Post.getPath(post.parent)}>{post.parent && "Related Post"}</Link> </Table.Cell>
                </Table.Row>)}
                <Table.Row>
                  <Table.Cell>Caption</Table.Cell>
                  <Table.Cell>{post.content}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Likes</Table.Cell>
                  <Table.Cell>{post.likesCount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Tagged Users</Table.Cell>
                  <Table.Cell>{post.taggedUsers.length == 0 ? "No tagged users" : post.taggedUsers.map(users =>{
                    return <Link to={User.getPath(users.id)} key = {users.id}><li> {users.name}</li></Link>; })}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Visibility</Table.Cell>
                  <Table.Cell>{post.visibility}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={2}>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(post.createdAt)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={2}>Updated At</Table.Cell>
                  <Table.Cell>{formatDateTime(post.updatedAt)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            
          {!isComment && (
              <React.Fragment>
                <Header as="h4"> Comments </Header>
                <SearchProvider onDataNeeded={this.onDataNeeded}>
                  {({ items: posts, items, getSorted, setSort, reload, loading, error }) => {
                    if (post.commentCount !== 0) {
                      return (
                        <React.Fragment>
                          {loading ? (
                            <Loader active />
                          ) : error ? (
                            <Message error content={error.message} />
                          ) : posts.length === 0 ? (
                            <Message>No comments created yet</Message>
                          ) : (
                            <Table celled sortable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell textAlign="center">
                                    Author
                                  </Table.HeaderCell>
                                  <Table.HeaderCell textAlign="center">
                                    Content
                                  </Table.HeaderCell>
                                  <Table.HeaderCell textAlign="center">
                                    Likes
                                  </Table.HeaderCell>
                                  <Table.HeaderCell textAlign="center"
                                    onClick={() => setSort('createdAt')}
                                    sorted={getSorted('createdAt')}>
                                    Created
                                    <HelpTip
                                      title="Created"
                                      text="This is the date and time the comment was created."
                                    />
                                  </Table.HeaderCell>
                                  <Table.HeaderCell textAlign="center"
                                  onClick={() => setSort('createdAt')}
                                  sorted={getSorted('createdAt')}>
                                    Updated
                                    <HelpTip
                                      title="Updated"
                                      text="This is the date and time the comment was updated."
                                    />
                                  </Table.HeaderCell>
                                  <Table.HeaderCell textAlign="center">
                                    Actions
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {items.map((comment) => {
                                  return (
                                    <Table.Row key={comment.id}>
                                      <Table.Cell width={3} textAlign="center">
                                      @${comment.author.nickname}<br/>({comment.author.name})
                                      </Table.Cell>
                                      <Table.Cell width={6} textAlign="center">
                                        {comment.content}
                                      </Table.Cell>
                                      <Table.Cell width={1} textAlign="center">
                                        {comment.likesCount}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        {formatDateTime(comment.createdAt)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        {formatDateTime(comment.updatedAt)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center" singleLine>
                                        <Link to={Post.getPath(comment.id)}>
                                          <Confirm
                                            negative
                                            confirmButton="Details"
                                            trigger={<Button basic icon="edit" />}
                                          />
                                        </Link>
                                          <Confirm
                                            negative
                                            confirmButton="Delete"
                                            header={`Are you sure you want to delete this comment?`}
                                            content="All data will be permanently deleted"
                                            trigger={<Button basic icon="trash" />}
                                            onConfirm={async () => {
                                              await request({
                                                method: 'DELETE',
                                                path: `/1/posts/${comment.id}`,
                                              });
                                              await reload(); 
                                            }}
                                          />
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                })}
                              </Table.Body>
                            </Table>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      return <Message> This post has no comments</Message>;
                    }
                  }}
                </SearchProvider>
              </React.Fragment>
          )}

          {post.parent && (
            <React.Fragment>
            <Header as="h4"> Post </Header>
            <SearchProvider onDataNeeded={this.onParentDataNeeded}>
              {({ items: items, getSorted, setSort, loading, error }) => {
                  return (
                    <React.Fragment>
                      {loading ? (
                        <Loader active />
                      ) : error ? (
                        <Message error content={error.message} />
                      ): (
                        <Table celled sortable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell textAlign="center">
                                Author
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center">
                                Content
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center">
                                Likes
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center"
                                onClick={() => setSort('createdAt')}
                                sorted={getSorted('createdAt')}>
                                Created
                                <HelpTip
                                  title="Created"
                                  text="This is the date and time the comment was created."
                                />
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center"
                              onClick={() => setSort('createdAt')}
                              sorted={getSorted('createdAt')}>
                                Updated
                                <HelpTip
                                  title="Updated"
                                  text="This is the date and time the comment was updated."
                                />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {isComment && (
                                <Table.Row>
                                    <Table.Cell width={3} textAlign="center">
                                    @${items.author.nickname}<br/>({items.author.name})
                                    </Table.Cell>
                                    <Table.Cell width={6} textAlign="center">
                                      {truncateString(items.content,50)}
                                    </Table.Cell>
                                    <Table.Cell width={1} textAlign="center">
                                      {items.likesCount}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {formatDateTime(items.createdAt)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {formatDateTime(items.updatedAt)}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      )}
                    </React.Fragment>
                  );
                }}
            </SearchProvider>
            </React.Fragment>)}

            <React.Fragment>
              <Header as="h4"> Reports </Header>
              <SearchProvider onDataNeeded={this.onDataNeeded}>
                {({ items: posts, getSorted, setSort, loading, error }) => {
                  if (reports.length !== 0) {
                    return (
                      <React.Fragment>
                        {loading ? (
                          <Loader active />
                        ) : error ? (
                          <Message error content={error.message} />
                        ) : posts.length === 0 ? (
                          <Message>No reports yet</Message>
                        ) : (
                          <Table celled sortable>
                            <Table.Header>
                              <Table.Row>
                              <Table.HeaderCell textAlign="center">
                                  Author
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center">
                                  Type
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center"
                                  onClick={() => setSort('createdAt')}
                                  sorted={getSorted('createdAt')}>
                                  Created
                                  <HelpTip
                                    title="Created"
                                    text="This is the date and time the report was created."
                                  />
                              </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center"
                                onClick={() => setSort('createdAt')}
                                sorted={getSorted('createdAt')}>
                                  Updated
                                  <HelpTip
                                    title="Updated"
                                    text="This is the date and time the report was updated."
                                  />
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {reports.map((report) => {
                                return (
                                  <Table.Row key={report.id}>
                                    <Table.Cell textAlign="center">
                                    @${report.author.nickname}<br/>({report.author.name})
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {report.type}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {formatDateTime(report.createdAt)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {formatDateTime(report.updatedAt)}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            </Table.Body>
                          </Table>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return <Message> This {isComment? "comment" : "post"} has no reports.</Message>;
                  }
                }}
              </SearchProvider>

              <Header as="h4"> Media </Header>
              {post.media ? post.media.mimeType.includes("image") ? (
              <Image.Group size="small">
                <Image src={`${API_URL}/1/uploads/${post.media.id}/image`}/>
              </Image.Group>
              ) : (
              <video controls src={`${API_URL}/1/uploads/${post.media.id}/image`} width="320" height="240"/>
              ) : <Message> No Media </Message>}
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
