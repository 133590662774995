import React from 'react';
import { Message, Grid } from 'semantic';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import LogoTitle from 'components/LogoTitle';
import Form from './Form';

import { Link } from 'react-router-dom';

@screen
export default class ForgotPassword extends React.Component {
  static layout = 'basic';

  state = {
    success: false,
    loading: false,
    error: null,
    email: null,
  };

  onSubmit = async (body) => {
    this.setState({
      error: null,
      loading: true,
    });
    try {
      await request({
        method: 'POST',
        path: '/1/auth/request-password',
        body,
      });
      this.setState({
        email: body.email,
        success: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { error, success, email, loading } = this.state;
    return (
      <React.Fragment>
        <LogoTitle title="Forgot Password" />
            {error && <Message error content={error.message} />}
            {success ? (
              <Message info>
                <Message.Header>Mail sent!</Message.Header>
                <p>
                We have sent a reset-link to the given email address.{' '}
                  <b>{email}</b>
                </p>
              </Message>
            ) : (
              <Form onSubmit={this.onSubmit} loading={loading} error={error} />
            )}
            <Grid>
              <Grid.Column textAlign="center">
                <Link to="/login">Login</Link>
              </Grid.Column>
            </Grid>
      </React.Fragment>
    );
  }
}
