import { hot } from 'react-hot-loader/root';

import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthSwitch, Protected } from 'helpers/routes';
import { useSession } from 'stores';

import Areas from 'screens/Areas';
import Broadcasts from 'screens/Broadcasts';
import Dashboard from 'screens/Dashboard';
import Docs from 'screens/Docs';
import Components from 'screens/Components';
import NotFound from 'screens/NotFound';
import Categories from 'screens/Categories';
import Promos from 'screens/Promos';
import Venues from 'screens/Venues';
import Users from 'screens/Users';
import UserReports from 'screens/UserReports';
import Posts from 'screens/Posts';
import PostReports from 'screens/PostReports';
import Opinions from 'screens/Opinions';

import AcceptInvite from 'screens/Auth/AcceptInvite';
import ForgotPassword from 'screens/Auth/ForgotPassword';
import ResetPassword from 'screens/Auth/ResetPassword';
import ConfirmAccess from 'screens/Auth/ConfirmAccess';
import MfaVerification from 'screens/Auth/MfaVerification';
import MfaBackupVerification from 'screens/Auth/MfaBackupVerification';
import Login from 'screens/Auth/Login';
import Logout from 'screens/Auth/Logout';
import Loading from 'screens/Loading';
import Error from 'screens/Error';
import OverpassRelations from 'screens/OverpassRelations';

const App = () => {
  const { loading, error } = useSession();

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error error={error} />;
  }

  return (
    <Switch>
      <AuthSwitch path="/" loggedIn={Dashboard} loggedOut={Login} exact />
      <Protected path="/areas/:id?" allowed={Areas} />
      <Protected path="/broadcasts/:id?" allowed={Broadcasts} />
      <Protected path="/categories/:id?" allowed={Categories} />
      <Protected path="/venues/:id?" allowed={Venues} />
      <Protected path="/promos/:id?" allowed={Promos} />
      <Protected path="/users/:id?" allowed={Users} />
      <Protected path="/posts/:id?" allowed={Posts} />
      <Protected path="/reports/posts" allowed={PostReports} />
      <Protected path="/reports/users" allowed={UserReports} />
      <Protected path="/opinions/:id?" allowed={Opinions} />
      <Protected path="/overpass-relations/:id?" allowed={OverpassRelations} />
      <Route path="/docs/ui" component={Components} exact />
      <Route path="/docs/:id?" component={Docs} />
      <Route path="/logout" component={Logout} exact />
      <AuthSwitch
        path="/login/verification"
        loggedOut={MfaVerification}
        loggedIn={() => <Redirect to="/" />}
        exact
      />
      <AuthSwitch
        path="/login/verification/backup"
        loggedOut={MfaBackupVerification}
        loggedIn={() => <Redirect to="/" />}
        exact
      />
      <AuthSwitch
        path="/login"
        loggedOut={Login}
        loggedIn={() => <Redirect to="/" />}
        exact
      />
      <Route path="/accept-invite" component={AcceptInvite} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/reset-password" component={ResetPassword} exact />
      <Protected path="/confirm-access" allowed={ConfirmAccess} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default hot(App);
