import React from 'react';
import { Button, Divider, Table, Message, Loader } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';

import 'styles/general.css';

@screen
export default class OverpassRelationsList extends React.Component {
  onDataNeeded = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/overpass-relations/search',
      body,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: overpassRelations,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <>
              <Breadcrumbs active="OverpassRelations" />

              <Layout horizontal center spread>
                <h1>OverpassRelations</h1>

                <div className="totalOverpassRelations">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' overpassRelation'
                    : meta.total + ' overpassRelations'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Relation name"
                    />
                  </Filters.Modal>
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : overpassRelations.length === 0 ? (
                <Message>No Overpass Relations created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Name
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Sync Status
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        # Venues
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the Overpass Relation was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {overpassRelations.map((overpassRelation) => {
                      return (
                        <Table.Row key={overpassRelation.id}>
                          <Table.Cell textAlign="center">
                            {overpassRelation.name}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {syncStatus(overpassRelation)}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {overpassRelation.venueCount}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(overpassRelation.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Button
                              basic
                              disabled={
                                overpassRelation.currentSync &&
                                !overpassRelation.currentSync.endedAt
                              }
                              icon="sync"
                              title="Sync"
                              onClick={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/overpass-relations/${overpassRelation.id}/sync`,
                                });
                                reload();
                              }}
                            />

                            <Button
                              basic
                              disabled={
                                !overpassRelation.currentSync ||
                                !!overpassRelation.currentSync.endedAt
                              }
                              icon="stop"
                              title="Cancel Sync"
                              onClick={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/overpass-relations/${overpassRelation.id}/cancel-sync`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </>
          );
        }}
      </SearchProvider>
    );
  }
}

function syncStatus(overpassRelation) {
  if (!overpassRelation.currentSync) return 'Not Synced';
  if (!overpassRelation.currentSync.startedAt) return 'Pending';
  if (!overpassRelation.currentSync.endedAt) return 'Syncing';
  return `Synced at ${formatDateTime(overpassRelation.currentSync.endedAt)}`;
}
