import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Container } from 'semantic';
import Footer from 'components/Footer';
import { Layout } from 'components';
import Organization from 'modals/Organization';
import Sidebar from './Sidebar';
import { withSession } from 'stores';
import { userCanSwitchOrganizations } from 'utils/permissions';
import ConnectionError from 'components/ConnectionError';
import logo from 'assets/Lyfe.svg';
import favicon from 'assets/favicon.svg';
import { GalleryProvider } from 'components/GalleryProvider.js';

@withSession
export default class DashboardLayout extends React.Component {
  render() {
    const { user, getOrganization } = this.context;
    return (
      <Sidebar>
        <ConnectionError />

        <Sidebar.Menu>
          <Layout style={{ height: '100%' }}>
            <NavLink style={{ margin: '5px 25px 20px 25px' }} to="/">
              <img width="100%" src={logo} />
            </NavLink>

            <Layout vertical spread>
              {userCanSwitchOrganizations(user) && (
                <Sidebar.Item>
                  <Organization
                    trigger={
                      <div>
                        <Icon name="building" />
                        {getOrganization()?.name || 'Select Organization'}
                        <Icon name="caret-down" className="right" />
                      </div>
                    }
                    size="tiny"
                  />
                </Sidebar.Item>
              )}
              <Layout.Group>
                <Sidebar.Header>Main menu</Sidebar.Header>
              </Layout.Group>

              <Layout.Group grow overflow>
                <Sidebar.Link to="/areas">
                  <Icon name="map-marker" />
                  Areas
                </Sidebar.Link>

                <Sidebar.Link to="/broadcasts">
                  <Icon name="microphone" />
                  Broadcasts
                </Sidebar.Link>

                <Sidebar.Link to="/overpass-relations">
                  <Icon name="map-signs" />
                  Overpass Relations
                </Sidebar.Link>

                <Sidebar.Link to="/categories">
                  <Icon name="clipboard" />
                  Categories
                </Sidebar.Link>

                <Sidebar.Link to="/venues">
                  <Icon name="store" />
                  Venues
                </Sidebar.Link>

                <Sidebar.Link to="/users">
                  <Icon name="users" />
                  Users
                </Sidebar.Link>

                <Sidebar.Link to="/reports/users">
                  <Icon name="ban" />
                  User Reports
                </Sidebar.Link>

                <Sidebar.Link to="/posts">
                  <Icon name="box" />
                  Posts
                </Sidebar.Link>

                <Sidebar.Link to="/reports/posts">
                  <Icon name="ban" />
                  Post Reports
                </Sidebar.Link>

                <Sidebar.Link to="/promos">
                  <Icon name="ticket-alt" />
                  Promos
                </Sidebar.Link>

                <Sidebar.Link to="/opinions">
                  <Icon name="bullhorn" />
                  Opinions
                </Sidebar.Link>
              </Layout.Group>

              <Layout.Group>
                <Sidebar.Divider />

                <Sidebar.Link to="/docs/getting-started">
                  <Icon name="terminal" />
                  Docs
                </Sidebar.Link>

                <Sidebar.Link to="/logout">
                  <Icon name="sign-out-alt" />
                  Log Out
                </Sidebar.Link>
              </Layout.Group>
            </Layout>
          </Layout>
        </Sidebar.Menu>

        <Sidebar.Content>
          <Sidebar.Mobile>
            <Layout horizontal spread center>
              <Layout.Group>
                <NavLink to="/">
                  <img src={favicon} height="15" />
                </NavLink>
              </Layout.Group>

              <Layout.Group>
                <Sidebar.Trigger>
                  <Icon name="bars" fitted />
                </Sidebar.Trigger>
              </Layout.Group>
            </Layout>
          </Sidebar.Mobile>

          <GalleryProvider>
            <Container>
              <main>{this.props.children}</main>
              <Footer />
            </Container>
          </GalleryProvider>
        </Sidebar.Content>
      </Sidebar>
    );
  }
}
