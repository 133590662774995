import React from 'react';
import { set } from 'lodash';
import { Form, Dropdown } from 'semantic';
import { GOOGLE_API_KEY } from 'utils/env';
import { Loader } from 'semantic-ui-react';
import { clampCoordinates } from 'components/Layout/utils';
import { Loader as GoogleLoader } from '@googlemaps/js-api-loader';

const GOOGLE_LOADER_OPTIONS = { apiKey: GOOGLE_API_KEY, language: 'en' };

function extractGoogleAddressComponent(
  addressComponents,
  type,
  attribute = 'long_name'
) {
  const result = addressComponents.filter((c) => c.types.includes(type))[0];
  return result ? result[attribute] : '';
}

function composeDefaultLookupValue(address) {
  if (!address || !address.line1) return '';
  const components = [address.line1];
  if (address.city) {
    components.push(address.city);
  }
  if (address.region) {
    components.push(address.region);
  }
  if (address.countryCode) {
    components.push(address.countryCode);
  }
  return components.join(', ');
}

export default class Address extends React.Component {
  state = {
    initializing: true,
    manualEntry: false,
    error: false,
    placeOptions: [],
    lookupValue: composeDefaultLookupValue(this.props.value),
  };

  componentDidMount() {
    this.loadGoogleDependency();
  }

  async loadGoogleDependency() {
    const { disableLookup } = this.props;

    if (window.google) {
      this.initializeLookup();
      this.setState({ initializing: false });
      return;
    }

    if (disableLookup || !GOOGLE_API_KEY) {
      this.setState({ initializing: false, manualEntry: true });
      return;
    }

    await this.initializeLookup();

    this.setState({ initializing: false });
  }

  async initializeLookup() {
    const googleLoader = new GoogleLoader(GOOGLE_LOADER_OPTIONS);
    await googleLoader.load();

    const { AutocompleteService } = await window.google.maps.importLibrary(
      'places'
    );
    const { Geocoder } = window.google.maps;

    this.autocompleteService = new AutocompleteService();
    this.geocoderService = new Geocoder();
  }

  setField = (e, { name, value }) => {
    const currentValue = this.props.value || {};
    set(currentValue, name, value);
    this.props.onChange(e, { name, value: currentValue });
  };

  getAutoCompleteName(key) {
    if (this.props.autoComplete == 'off') {
      return 'off';
    }
    if (key === 'region') {
      return 'address-level1';
    }
    if (key === 'city') {
      return 'address-level2';
    }
    if (key === 'countryCode') {
      return 'address-country';
    }
    if (key === 'postalCode') {
      return 'address-postal-code';
    }
    return `address-${key}`;
  }

  onLookupSearchChange(searchQuery) {
    if (searchQuery.length) {
      this.autocompleteService.getPlacePredictions(
        { input: searchQuery },
        (placeOptions) => {
          this.setState({ placeOptions });
        }
      );
    }
  }

  onLookupResultChange(value) {
    let placeOption = this.state.placeOptions.find(
      (o) => o.description === value
    );

    const geoCodeOptions = placeOption
      ? { placeId: placeOption.place_id }
      : { address: value };

    this.geocoderService.geocode(geoCodeOptions, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0] && results[0].geometry) {
          const { address_components } = results[0];
          const { location } = results[0].geometry;

          const geometry = {
            type: 'Point',
            coordinates: [location.lng(), location.lat()],
          };

          const address = {
            geometry,
            line1: `${extractGoogleAddressComponent(
              address_components,
              'street_number'
            )} ${extractGoogleAddressComponent(address_components, 'route')}`,
            city: extractGoogleAddressComponent(address_components, 'locality'),
            region: extractGoogleAddressComponent(
              address_components,
              'administrative_area_level_1',
              'short_name'
            ),
            countryCode: extractGoogleAddressComponent(
              address_components,
              'country',
              'short_name'
            ),
            postalCode: extractGoogleAddressComponent(
              address_components,
              'postal_code'
            ),
          };

          this.props.onChange(null, { name: this.props.name, value: address });

          const [lon, lat] = address.geometry.coordinates;

          this.setField(null, { name: 'lat', value: lat });
          this.setField(null, { name: 'lon', value: lon });
          this.props.setField({}, { name: 'address', value });
        }
      }
    });
  }

  renderLookupDropdown() {
    const { placeOptions, error, lookupValue } = this.state;

    const options = (placeOptions || []).map((place) => {
      return {
        value: place.description,
        text: place.description,
        key: place.description,
      };
    });

    if (!options.map((o) => o.value).includes(lookupValue)) {
      options.push({
        value: lookupValue,
        text: lookupValue,
        key: lookupValue,
      });
    }

    return (
      <Form.Field error={error.message} required>
        <label>Address</label>

        <Dropdown
          fluid
          clearable
          selection
          search
          name="line1"
          defaultValue={lookupValue}
          options={options}
          onChange={(e, { value }) => this.onLookupResultChange(value)}
          onSearchChange={(e, { searchQuery }) =>
            this.onLookupSearchChange(searchQuery)
          }
        />
      </Form.Field>
    );
  }

  renderFields() {
    const { manualEntry } = this.state;
    const { value } = this.props;

    return (
      <>
        {this.autocompleteService && this.renderLookupDropdown()}

        {manualEntry ? (
          <>
            <Form.Input
              type="number"
              onWheel={(scroll) => scroll.target.blur()}
              min="-90"
              max="90"
              step="any"
              name="lat"
              label="Latitude"
              value={clampCoordinates(value[1], -90, 90)}
              onChange={this.setField}
            />

            <Form.Input
              type="number"
              onWheel={(scroll) => scroll.target.blur()}
              min="-180"
              max="180"
              step="any"
              name="lon"
              label="Longitude"
              value={clampCoordinates(value[0], -180, 180)}
              onChange={this.setField}
            />
          </>
        ) : (
          <a
            onClick={() => this.setState({ manualEntry: true })}
            style={{ cursor: 'pointer' }}>
            Show Coordinates
          </a>
        )}
      </>
    );
  }

  render() {
    const { initializing } = this.state;
    return initializing ? <Loader active /> : this.renderFields();
  }
}
