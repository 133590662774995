import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Message, Divider, Loader, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { truncateString } from 'utils/formatting';
import EditBroadcast from 'modals/EditBroadcast';
import 'styles/general.css';

@screen
export default class BroadcastList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/broadcasts/search',
      body: params,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: broadcasts,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Broadcasts" />
              <Layout horizontal center spread>
                <h1>Broadcasts</h1>

                <div className="totalBroadcasts">
                  {meta.total == 1
                    ? meta.total + ' broadcast'
                    : meta.total + ' broadcasts'}
                </div>

                <Layout.Group center>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Search for exact broadcast or venue"
                    />
                  </Filters.Modal>

                  <EditBroadcast
                    trigger={
                      <Button primary content="New Broadcast" icon="plus" />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : broadcasts.length === 0 ? (
                <Message>No results</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Title{' '}
                        <HelpTip
                          title="Title"
                          text="The user won't see this, for admin only."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Content{' '}
                        <HelpTip
                          title="Content"
                          text="Sent as the notification and message payload."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Venue{' '}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        {' '}
                        Publish at{' '}
                        <HelpTip
                          title="Publish at"
                          text="This is the date and time the broadcast will be sent."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Published At{' '}
                        <HelpTip
                          title="Published at"
                          text="This is the date and time the broadcast finished sending."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Actions{' '}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {broadcasts.map((broadcast) => {
                      return (
                        <Table.Row key={broadcast.id}>
                          <Table.Cell> {broadcast.title} </Table.Cell>
                          <Table.Cell width={3}>
                            {' '}
                            {truncateString(broadcast.content, 40)}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {broadcast.venue?.name || '-'}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {broadcast.publishAt
                              ? formatDateTime(broadcast.publishAt)
                              : '-'}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {broadcast.publishedAt
                              ? formatDateTime(broadcast.publishedAt)
                              : '-'}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <Link to={`/broadcasts/${broadcast.id}`}>
                              <EditBroadcast
                                broadcast={broadcast}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Link>
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete this broadcast?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/broadcasts/${broadcast.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
