import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Modal, Table } from 'semantic';
import { capitalize } from 'lodash';

import AutoFocus from 'components/AutoFocus';
import { SearchProvider } from 'components/search';

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import modal from 'helpers/modal';

@modal
export default class ListCheckins extends React.Component {
  onDataNeeded = async (opts) => {
    const { user, venue } = this.props;

    const params = { ...opts };
    if (user) params.user = user.id;
    if (venue) params.venue = venue.id;

    delete params.sort;

    return await request({
      method: 'GET',
      path: '/1/checkins',
      params,
    });
  };

  render() {
    return (
      <>
        <Modal.Header>{header(this.props.user, this.props.venue)}</Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <SearchProvider limit={10} onDataNeeded={this.onDataNeeded}>
              {({ items: checkins }) => (
                <>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {this.props.user ? 'Venue' : 'User'}
                        </Table.HeaderCell>

                        <Table.HeaderCell>Status</Table.HeaderCell>

                        <Table.HeaderCell>Private?</Table.HeaderCell>

                        <Table.HeaderCell>Created</Table.HeaderCell>

                        <Table.HeaderCell>Activated</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {checkins.map((checkin) => (
                        <Table.Row key={checkin.id}>
                          <Table.Cell>
                            {this.props.user ? (
                              locationLink(checkin)
                            ) : (
                              <Link to={`/users/${checkin.user.id}`}>
                                {userName(checkin.user)}
                              </Link>
                            )}
                          </Table.Cell>

                          <Table.Cell>{capitalize(checkin.type)}</Table.Cell>

                          <Table.Cell textAlign="center">
                            {checkin.privacy === 'PRIVATE' ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell>
                            {formatDateTime(checkin.createdAt)}
                          </Table.Cell>

                          <Table.Cell>
                            {checkin.activatedAt
                              ? formatDateTime(checkin.activatedAt)
                              : '-'}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>

                  <Divider hidden />

                  <SearchProvider.Pagination />
                </>
              )}
            </SearchProvider>
          </AutoFocus>
        </Modal.Content>
      </>
    );
  }
}

function header(user, venue) {
  if (user) return `${userName(user)}'s checkins`;
  if (venue) return `${venue.name}'s checkins`;
  throw new TypeError('Need either a user of a venue');
}

function userName(user) {
  return `${user.firstName} ${user.lastName}`;
}

function locationLink(checkin) {
  if (checkin.venue) {
    return <Link to={`/venues/${checkin.venue.id}`}>{checkin.venue.name}</Link>;
  }

  return <Link to={`/areas/${checkin.area.id}`}>{checkin.area.name}</Link>;
}
