import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

@modal
export default class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      post: props.post || {},
    };
  }

  isUpdate() {
    return !!this.props.post;
  }

  setField(name, value) {
    this.setState({
      post: {
        ...this.state.post,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    const { post } = this.state;
    this.setState({
      loading: true,
      touched: true,
    });

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/posts/${post.id}`,
          body: {
            ...post,
            roles: undefined,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/posts',
          body: post,
        });
      }
      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { post, touched, loading, error } = this.state;
    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit Caption` : 'New Post'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              id="edit-post"
              onSubmit={this.onSubmit}
              error={touched && !!error}>
              {error && <Message error content={error.message} />}
              <Form.Input
                value= {post.content || ''}
                label="Caption"
                required
                type="text"
                autoComplete="given-post"
                onChange={(e, { value }) => this.setField('content', value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-post"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
