import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Protected } from 'helpers/routes';
import { Loader } from 'semantic';
import { request } from 'utils/api';
import NotFound from 'screens/NotFound';

import Overview from './Overview';

export default class AreaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area: null,
      error: null,
      loading: true,
    };
  }

  onSave = () => {
    this.fetchArea();
  };

  componentDidMount() {
    this.fetchArea();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchArea();
    }
  }

  async fetchArea() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });

      const { data } = await request({
        method: 'GET',
        path: `/1/areas/${id}`,
      });

      this.setState({
        area: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <NotFound
          link={<Link to="/areas">Areas</Link>}
          message="Sorry that area wasn't found."
        />
      );
    }

    const props = {
      ...this.state,
      onSave: this.onSave,
    };
    return (
      <Switch>
        <Protected exact path="/areas/:id" allowed={Overview} {...props} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
