import React from 'react';
import './ImageOverlay.css';

export default ({shownImage, onClose}) => {
    return (
        <div className="imageOverlay">
            <button className="closeButton" onClick={onClose}> X </button>
            <div className="image-container">
                <img className='enlargedImage' src={shownImage?.src} />
                <div className='imageTitle'>{shownImage?.title}</div>
            </div>
        </div>
    );
}; 