import React from 'react';
import { Table, Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { GalleryContext } from 'components/GalleryProvider.js';

@screen
export default class AreaOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleOverlay = (element) => {
    this.context.setImage(
      element.target.src,
      element.target.getAttribute('image-name')
    );
  };

  static contextType = GalleryContext;

  render() {
    const { area } = this.props;

    return (
      <React.Fragment>
        <Menu {...this.props} />

        <Divider hidden />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{area.name}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Highlighted</Table.Cell>
              <Table.Cell>{area.highlighted ? '✅' : '❌'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Location</Table.Cell>
              <Table.Cell>
                {area.area.type === 'Point'
                  ? `Latitude: ${area.area.coordinates[1]}, Longitude: ${area.area.coordinates[0]}`
                  : 'Polygon'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
