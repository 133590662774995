import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Confirm, Table, Message, Divider, Loader } from 'semantic';

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';

@screen
export default class UserReportList extends React.Component {
  onDataNeeded = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/reports/users/search',
      body,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: reports,
          meta,
          getSorted,
          reload,
          setSort,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="User reports" />

              <Layout horizontal center spread>
                <h1>User reports</h1>

                <div className="user-reports">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' report'
                    : meta.total + ' reports'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Report text"
                    />
                  </Filters.Modal>
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : reports.length === 0 ? (
                <Message>No users reported on yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        Reporter
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Reported User
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Type
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Status
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the report was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {reports.map((report) => {
                      return (
                        <Table.Row key={report.id}>
                          <Table.Cell textAlign="center">
                            <Link to={`/users/${report.sourceUser.id}`}>
                              {report.sourceUser.name}
                            </Link>
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            <Link to={`/users/${report.destinationUser.id}`}>
                              {report.destinationUser.name}
                            </Link>
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {report.type}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {report.status}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(report.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={
                                'Are you sure you want to delete this report?'
                              }
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/reports/users/${report.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
