import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { Breadcrumbs, Layout } from 'components';

import EditBroadcast from 'modals/EditBroadcast';

export default function BroadcastMenu({ broadcast, onSave }) {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/broadcasts">Broadcasts</Link>}
        active={broadcast.title}
      />

      <Layout horizontal center spread>
        <h1>{broadcast.title}</h1>

        <Layout.Group>
          <EditBroadcast
            broadcast={broadcast}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Edit Broadcast" />}
          />
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item
          exact
          name="Overview"
          to={`/broadcasts/${broadcast.id}`}
          as={NavLink}
        />
      </Menu>
    </React.Fragment>
  );
}
