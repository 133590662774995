import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Message, Divider, Loader } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import EditUser from 'modals/EditUser';
import ListCheckins from 'modals/ListCheckins';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

@screen
export default class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reported: false,
      showingCheckinsFor: null,
    };
  }

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...params,
        reported: this.state.reported,
      },
    });
  };

  toggleReported = () => {
    this.setState({ reported: !this.state.reported });
  };

  showCheckins = (user) => {
    this.setState({ showingCheckinsFor: user });
  };

  hideCheckins = () => {
    this.setState({ showingCheckinsFor: null });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: users,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <ListCheckins
                open={!!this.state.showingCheckinsFor}
                user={this.state.showingCheckinsFor}
                onClose={this.hideCheckins}
              />

              <Breadcrumbs active="Users" />

              <Layout horizontal center spread>
                <h1>Users</h1>

                <div className="totalUsers">
                  {meta.total == 1
                    ? meta.total + ' user'
                    : meta.total + ' users'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter name, email, or nickname"
                    />
                    <ToggleSwitch
                      isOn={this.state.reported}
                      onColor={'#a099c9'}
                      onClick={() => {
                        this.toggleReported();
                      }}
                    />
                  </Filters.Modal>

                  <EditUser
                    trigger={<Button primary content="New User" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : users.length === 0 ? (
                <Message>
                  {this.state.reported
                    ? "There aren't any reported users yet"
                    : 'No results'}
                </Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        First name
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Last name
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Username
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('email')}
                        sorted={getSorted('email')}>
                        Email
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('phone')}
                        sorted={getSorted('phone')}>
                        Phone
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Joined
                        <HelpTip
                          title="Joined"
                          text="This is the date and time the user was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('statistics.posts')}
                        sorted={getSorted('statistics.posts')}>
                        Post count
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('statistics.checkins')}
                        sorted={getSorted('statistics.checkins')}>
                        Check-ins
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Enabled{' '}
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Actions{' '}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {users.map((user) => (
                      <Table.Row key={user.id}>
                        <Table.Cell textAlign="center">
                          {' '}
                          {user.firstName}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user.lastName}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user.nickname}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user.email}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user.phone}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {formatDateTime(user.createdAt)}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user?.statistics?.posts ?? '0'}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {user?.statistics?.checkins ?? '0'}{' '}
                          <Button
                            icon="eye"
                            onClick={() => this.showCheckins(user)}
                          />
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          {' '}
                          {user.disabled ? 'No' : 'Yes'}{' '}
                        </Table.Cell>

                        <Table.Cell textAlign="center" singleLine>
                          <Link to={`/users/${user.id}`}>
                            <EditUser
                              user={user}
                              trigger={<Button basic icon="edit" />}
                              onSave={reload}
                            />
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
