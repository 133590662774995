import React from 'react';
import { Form } from 'semantic';

import { request } from 'utils/api';

import SearchDropdown from '../SearchDropdown';

export default class Venues extends React.Component {
  fetchVenues = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/venues/full-search',
      body: { keyword },
    });

    return data;
  };

  render() {
    return (
      <Form.Field>
        <label>{this.props.multiple ? 'Venues' : 'Venue'}</label>

        <SearchDropdown fluid onDataNeeded={this.fetchVenues} {...this.props} />
      </Form.Field>
    );
  }
}

Venues.defaultProps = {
  multiple: true,
};
