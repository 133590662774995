import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

export default function Promos() {
  return (
    <Switch>
      <Route path="/promos" component={List} exact />
      <Route path="/promos/:id" component={Detail} />
    </Switch>
  );
}
