import React from "react";
import "./googleMaps.css";
import {GOOGLE_API_KEY} from "utils/env";
import GoogleMapReact from "google-map-react";
import logo from 'components/GoogleMaps/googleMarker.svg';

export default function Map({ coordinates }) {

  const MyMarker = () => {
    return (
        <img className="imageMarker" src={logo} />
    );
  };

  return (
    <div className="Map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          language: "en"
        }}
        defaultCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
        defaultZoom={16}
      >
      <MyMarker
        lat={coordinates.latitude}
        lng={coordinates.longitude}
      />
      </GoogleMapReact>
    </div>
  );
}