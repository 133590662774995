import React from 'react';
import { Button, Divider, Table, Message, Loader } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import EditArea from 'modals/EditArea';
import 'styles/general.css';
import { Link } from 'react-router-dom';

@screen
export default class AreasList extends React.Component {
  onDataNeeded = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/areas/search',
      body,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: areas,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <>
              <Breadcrumbs active="Areas" />

              <Layout horizontal center spread>
                <h1>Areas</h1>

                <div className="totalAreas">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' area'
                    : meta.total + ' areas'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter City name"
                    />
                    <Filters.Checkbox
                      name="includeZillow"
                      label="Include Zillow"
                    />
                  </Filters.Modal>

                  <EditArea
                    trigger={<Button primary content="New Area" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : areas.length === 0 ? (
                <Message>No areas created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Name
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('highlighted')}
                        sorted={getSorted('highlighted')}>
                        Highlighted
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center" width={3}>
                        City
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the area was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {areas.map((area) => {
                      return (
                        <Table.Row key={area.id}>
                          <Table.Cell textAlign="center">
                            {area.name}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {area.highlighted ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {area.city?.name || '-'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(area.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Link to={`/areas/${area.id}`}>
                              <EditArea
                                area={area}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Link>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </>
          );
        }}
      </SearchProvider>
    );
  }
}
