import React from 'react';
import { Button, Table, Message, Loader } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { truncateString } from 'utils/formatting';
import 'styles/general.css';
import EditCategory from 'modals/EditCategory';

@screen
export default class CategoriesList extends React.Component {
  onDataNeeded = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/categories/search',
      body,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: categories,
          meta,
          getSorted,
          reload,
          setSort,
          loading,
          error,
        }) => {
          return (
            <>
              <Breadcrumbs active="Categories" />

              <Layout horizontal center spread>
                <h1>Categories</h1>

                <div className="totalCategories">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' category'
                    : meta.total + ' categories'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Category name"
                    />
                  </Filters.Modal>

                  <EditCategory
                    trigger={
                      <Button primary content="New Category" icon="plus" />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : categories.length === 0 ? (
                <Message>No categories created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Name
                      </Table.HeaderCell>

                      <Table.HeaderCell width={3} textAlign="center">
                        Description
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        # Tag Groups
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        # Venues
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Has Image?
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Places Types
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the category was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {categories.map((category) => {
                      return (
                        <Table.Row key={category.id}>
                          <Table.Cell textAlign="center">
                            {category.name}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {truncateString(category.description, 80)}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {category.tagGroups.length}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {category.venuesCount}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {category.image ? 'Yes' : 'No'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {category.placesTypes?.join(', ') || '-'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(category.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <EditCategory
                              category={category}
                              trigger={<Button icon="edit" />}
                              onSave={reload}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </>
          );
        }}
      </SearchProvider>
    );
  }
}
