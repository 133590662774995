import React from 'react';
import { Table, Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { formatDateTime } from 'utils/date';
import { GalleryContext } from 'components/GalleryProvider.js';

@screen
export default class BroadcastOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleOverlay = (element) => {
    this.context.setImage(
      element.target.src,
      element.target.getAttribute('image-name')
    );
  };

  static contextType = GalleryContext;

  render() {
    const { broadcast } = this.props;

    return (
      <React.Fragment>
        <Menu {...this.props} />

        <Divider hidden />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>{broadcast.title}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Content</Table.Cell>
              <Table.Cell>{broadcast.content}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Venue</Table.Cell>
              <Table.Cell>{broadcast.venue?.name || '-'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Publish at</Table.Cell>
              <Table.Cell>
                {broadcast.publishAt
                  ? formatDateTime(broadcast.publishAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Published at</Table.Cell>
              <Table.Cell>
                {broadcast.publishedAt
                  ? formatDateTime(broadcast.publishedAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
