import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

export default function Areas() {
  return (
    <Switch>
      <Route path="/broadcasts" component={List} exact />
      <Route path="/broadcasts/:id" component={Detail} />
    </Switch>
  );
}
