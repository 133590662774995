import React from 'react';

import { Button, Confirm, Divider, Loader, Message, Table } from 'semantic';

const pluralize = require('pluralize');
const { isInteger, map, sortBy } = require('lodash');

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { Link } from 'react-router-dom';
import { truncateString } from 'utils/formatting';
import EditPromo from 'modals/EditPromo';
import ListRedemptions from 'modals/ListRedemptions';

import 'styles/general.css';

@screen
export default class PromoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingRedemptionsFor: null,
    };
  }

  onDataNeeded = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/promos/search',
      body,
    });
  };

  showRedemptions = (promo) => {
    this.setState({ showingRedemptionsFor: promo });
  };

  hideRedemptions = () => {
    this.setState({ showingRedemptionsFor: null });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: promos,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <ListRedemptions
                open={!!this.state.showingRedemptionsFor}
                promo={this.state.showingRedemptionsFor}
                onClose={this.hideRedemptions}
              />

              <Breadcrumbs active="Promos" />

              <Layout horizontal center spread>
                <h1>Promos</h1>

                <div className="totalPromos">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' promo'
                    : meta.total + ' promos'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Promo title"
                    />
                  </Filters.Modal>

                  <EditPromo
                    venues={promos}
                    trigger={<Button primary content="New Promo" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : promos.length === 0 ? (
                <Message>No promos created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        onClick={() => setSort('_id')}
                        sorted={getSorted('_id')}>
                        Date Created
                      </Table.HeaderCell>

                      <Table.HeaderCell>Title</Table.HeaderCell>

                      <Table.HeaderCell>Venues</Table.HeaderCell>

                      <Table.HeaderCell>Repeats</Table.HeaderCell>

                      <Table.HeaderCell>Start</Table.HeaderCell>

                      <Table.HeaderCell>End</Table.HeaderCell>

                      <Table.HeaderCell>Max Redemptions</Table.HeaderCell>

                      <Table.HeaderCell
                        onClick={() => setSort('statistics.redemptions')}
                        sorted={getSorted('statistics.redemptions')}>
                        # Redeemed
                      </Table.HeaderCell>

                      <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {promos.map((promo) => {
                      return (
                        <Table.Row key={promo.id}>
                          <Table.Cell>
                            {formatDateTime(promo.createdAt)}
                          </Table.Cell>

                          <Table.Cell>{promo.title}</Table.Cell>

                          <Table.Cell>
                            <span>
                              {`${promo.venues.length} ${pluralize(
                                'venue',
                                promo.venues.length
                              )}`}
                            </span>

                            <br />

                            <span>
                              {truncateString(
                                map(promo.venues, 'name').join(', '),
                                80
                              )}
                            </span>
                          </Table.Cell>

                          <Table.Cell>{periodicity(promo)}</Table.Cell>

                          <Table.Cell>
                            {formatDateTime(promo.startsAt)}
                          </Table.Cell>

                          <Table.Cell>
                            {promo.endsAt ? formatDateTime(promo.endsAt) : '-'}
                          </Table.Cell>

                          <Table.Cell>
                            {isInteger(promo.maxRedemptions)
                              ? promo.maxRedemptions
                              : '-'}
                          </Table.Cell>

                          <Table.Cell>
                            {promo.statistics.redemptions}
                            <Button
                              icon="eye"
                              onClick={() => this.showRedemptions(promo)}
                            />
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Link to={`/promos/${promo.id}`}>
                              <EditPromo
                                promo={promo}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Link>

                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${promo.title}"?`}
                              content="Any active promotions will be inactivated, and all pending redemptions will be deleted."
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/promos/${promo.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}

function periodicity(promo) {
  if (promo.period === 'NONE') return 'Does not repeat';
  if (promo.period === 'DAILY') {
    const plural = pluralize('hours', promo.dailyConfig?.activeHours);
    return `Daily for ${promo.dailyConfig?.activeHours} ${plural}`;
  }
  if (promo.period === 'WEEKLY') {
    return `Weekly (${weekdays(promo.weeklyConfig?.weekdays)})`;
  }
  if (promo.period === 'MONTHLY') return 'Monthly';
  return promo.period;
}

function weekdays(days) {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  if (!days) return '-';
  return sortBy(days)
    .map((day) => weekdays[day - 1])
    .join(', ');
}
