import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import EditArea from 'modals/EditArea';

export default ({ area, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs link={<Link to="/areas">Areas</Link>} active={area.name} />

      <Layout horizontal center spread>
        <h1>{area.name}</h1>

        <Layout.Group>
          <EditArea
            area={area}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Edit Area" />}
          />
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/areas/${area.id}`}
          as={NavLink}
          exact
        />
      </Menu>
    </React.Fragment>
  );
};
