import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Modal, Table } from 'semantic';

import AutoFocus from 'components/AutoFocus';
import { SearchProvider } from 'components/search';

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import modal from 'helpers/modal';

@modal
export default class ListPromos extends React.Component {
  onDataNeeded = async (opts) => {
    const { promo } = this.props;

    const params = { ...opts };
    params.promo = promo.id;

    delete params.sort;

    return await request({
      method: 'GET',
      path: `/1/promos/${promo.id}/redemptions`,
      params,
    });
  };

  render() {
    return (
      <>
        <Modal.Header>{header(this.props.promo)}</Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <SearchProvider limit={10} onDataNeeded={this.onDataNeeded}>
              {({ items: redemptions }) => (
                <>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>User</Table.HeaderCell>

                        <Table.HeaderCell>Venue</Table.HeaderCell>

                        <Table.HeaderCell>Activated</Table.HeaderCell>

                        <Table.HeaderCell>Redeemed</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {redemptions.map((redemption) => (
                        <Table.Row key={redemption.id}>
                          <Table.Cell>
                            <Link to={`/users/${redemption.user.id}`}>
                              {userName(redemption.user)}
                            </Link>
                          </Table.Cell>

                          <Table.Cell>
                            <Link
                              to={`/venues/${redemption.promoPeriod.venue.id}`}>
                              {redemption.promoPeriod.venue.name}
                            </Link>
                          </Table.Cell>

                          <Table.Cell>
                            {formatDateTime(redemption.activatedAt)}
                          </Table.Cell>

                          <Table.Cell>
                            {redemption.redeemedAt
                              ? formatDateTime(redemption.redeemedAt)
                              : '-'}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>

                  <Divider hidden />

                  <SearchProvider.Pagination />
                </>
              )}
            </SearchProvider>
          </AutoFocus>
        </Modal.Content>
      </>
    );
  }
}

function header(promo) {
  if (promo) return `${promo.title}'s redemptions`;
  throw new TypeError('Need a promo');
}

function userName(user) {
  return `${user.firstName} ${user.lastName}`;
}
