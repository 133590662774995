import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Confirm } from 'semantic';
import { Breadcrumbs, Layout } from 'components';
import { request } from 'utils/api';
import { useHistory } from 'react-router-dom';

export default function PostDetailMenu({ post }) {
  const isPost = Boolean(post.parent);
  let history = useHistory();

  return (
    <React.Fragment>
      {isPost ? (
        <Breadcrumbs
          link={<Link to={`/posts/${post.parent}`}> Post </Link>}
          active={post.id}
        />
      ) : (
        <Breadcrumbs link={<Link to="/posts">Posts</Link>} active={post.id} />
      )}

      <Layout horizontal center spread>
        <h1 style={{ textTransform: 'capitalize' }}>
          {isPost ? 'Comment' : 'Post'}
        </h1>

        <Layout.Group>
          <Confirm
            negative
            confirmButton="Delete"
            header={`Are you sure you want to delete this ${
              isPost ? 'comment' : 'post'
            }`}
            content="All data will be permanently deleted"
            trigger={<Button basic icon="trash" />}
            onConfirm={async () => {
              await request({
                method: 'DELETE',
                path: `/1/posts/${post.id}`,
              });
              history.push('/posts');
            }}
          />
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item name="Overview" />
      </Menu>
    </React.Fragment>
  );
}
