import React from 'react';
import { withRouter } from 'react-router-dom';
import ImageOverlay from 'components/ImageOverlay/ImageOverlay';

export const GalleryContext = React.createContext();

@withRouter
export class GalleryProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shownImage: {
        src: null,
        title: null,
      },
    };
  }
  
  setImage = (src, title) => {
    this.setState({ shownImage: { src, title }});
  }
  close = () => {
    this.setState({ shownImage: { src: null ,title: null}});
  }

  render() {
    return (

      <GalleryContext.Provider value={{...this.state, setImage: this.setImage}}>
        {this.state.shownImage.src ?  <ImageOverlay  shownImage={this.state.shownImage} onClose={this.close}/>: this.props.children}
      </GalleryContext.Provider>
    );
  }
}
