import React from 'react';
import { clone, pick } from 'lodash';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

@modal
export default class EditArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      area: props.area || { area: { type: 'Point', coordinates: [] } },
    };
  }

  isUpdate() {
    return !!this.props.area;
  }

  setField = (_evt, { name, value }) => {
    this.setState({
      area: {
        ...this.state.area,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  setCoordinate = (_evt, { name, value }) => {
    const coordinates = clone(this.state.area.area.coordinates);

    if (name === 'latitude') {
      coordinates[1] = Number(value);
    } else {
      coordinates[0] = Number(value);
    }

    this.setState({
      area: {
        ...this.state.area,
        area: {
          ...this.state.area.area,
          coordinates,
        },
      },
    });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { area } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PUT',
          path: `/1/areas/${area.id}`,
          body: pick(area, ['name', 'highlighted', 'area']),
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/areas',
          body: area,
        });
      }

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { area, loading, error } = this.state;

    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Area` : 'New Area'}
        </Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-area"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={area.name || ''}
                onChange={this.setField}
              />
              <Form.Checkbox
                name="highlighted"
                label="Highlighted"
                checked={area.highlighted}
                onChange={this.setCheckedField}
              />

              {area.area.type === 'Point' && (
                <Form.Group>
                  <Form.Input
                    required
                    type="number"
                    min="-90"
                    max="90"
                    name="latitude"
                    label="Latitude"
                    value={area.area.coordinates[1] || ''}
                    onChange={this.setCoordinate}
                  />
                  <Form.Input
                    required
                    type="number"
                    min="-180"
                    max="180"
                    name="longitude"
                    label="Longitude"
                    value={area.area.coordinates[0] || ''}
                    onChange={this.setCoordinate}
                  />
                </Form.Group>
              )}
            </Form>
          </AutoFocus>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            form="edit-area"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
