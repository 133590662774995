import React from 'react';
import { Table, Divider } from 'semantic';
import { isInteger } from 'lodash';
import screen from 'helpers/screen';
import Menu from './Menu';
import { Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import { GalleryContext } from 'components/GalleryProvider.js';

@screen
export default class PromoOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shownImage: { src: null, title: null },
    };
  }

  handleOverlay = (element) => {
    this.context.setImage(
      element.target.src,
      element.target.getAttribute('image-name')
    );
  };

  static contextType = GalleryContext;

  render() {
    const { promo } = this.props;

    return (
      <React.Fragment>
        <Menu {...this.props} />

        <Divider hidden />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>{promo.title}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Short Description</Table.Cell>
              <Table.Cell>{promo.shortDescription}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Full Description</Table.Cell>
              <Table.Cell>{promo.fullDescription}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Redemption Copy</Table.Cell>
              <Table.Cell>{promo.redemptionCopy}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Venues</Table.Cell>

              <Table.Cell>
                {promo.venues.map((venue) => (
                  <Label key={venue.id} content={venue.name} />
                ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Start Date</Table.Cell>
              <Table.Cell>{formatDateTime(promo.startsAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>End Date</Table.Cell>
              <Table.Cell>
                {promo.endsAt ? formatDateTime(promo.endsAt) : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Max Redemptions</Table.Cell>
              <Table.Cell>
                {isInteger(promo.maxRedemptions) ? promo.maxRedemptions : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(promo.createdAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(promo.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
