import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Confirm } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import { request } from 'utils/api';
import EditVenue from 'modals/EditVenue';
import { useHistory } from 'react-router-dom';

export default ({ categories, venue, onSave }) => {
  const [isSyncing, setIsSyncing] = useState(false);
  let history = useHistory();

  const handleSync = async () => {
    setIsSyncing(true);

    try {
      await request({
        method: 'POST',
        path: `/1/venues/${venue.id}/sync`,
      });

      onSave();
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/venues">Venues</Link>}
        active={venue.name}
      />

      <Layout horizontal center spread>
        <h1>{venue.name}</h1>

        <Layout.Group>
          <Button
            basic
            content="Sync Venue"
            disabled={isSyncing}
            icon="sync"
            title="sync"
            onClick={handleSync}
          />

          <EditVenue
            categories={categories}
            venue={venue}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Edit Venue" />}
          />

          <Confirm
            negative
            confirmButton="Delete"
            header={`Are you sure you want to delete "${venue.name}"?`}
            content="All data will be permanently deleted"
            trigger={<Button basic icon="trash" />}
            onConfirm={async () => {
              await request({
                method: 'DELETE',
                path: `/1/venues/${venue.id}`,
              });
              history.push('/venues');
            }}
          />
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/venues/${venue.id}`}
          as={NavLink}
          exact
        />
      </Menu>
    </React.Fragment>
  );
};
