import React from 'react';

import {
  Table,
  Button,
  Message,
  Divider,
  Loader,
  Confirm,
  Label,
} from 'semantic';

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { Link } from 'react-router-dom';
import { truncateString } from 'utils/formatting';
import EditVenue from 'modals/EditVenue';
import ListCheckins from 'modals/ListCheckins';
import 'styles/general.css';
import { isNil } from 'lodash';

@screen
export default class VenueList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingCheckinsFor: null,
      discoverableFilter: null,
    };
  }

  onDataNeeded = async (body) => {
    if (!isNil(this.state.discoverableFilter)) {
      body = { ...body, discoverable: this.state.discoverableFilter };
    }

    return await request({
      method: 'POST',
      path: '/1/venues/full-search',
      body,
    });
  };

  showCheckins = (venue) => {
    this.setState({ showingCheckinsFor: venue });
  };

  hideCheckins = () => {
    this.setState({ showingCheckinsFor: null });
  };

  setDiscoverableFilter = (value) => {
    this.setState({ discoverableFilter: value });
  };

  resetDiscoverableFilter = () => {
    this.setState({ discoverableFilter: null });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: venues,
          meta,
          categories,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <ListCheckins
                open={!!this.state.showingCheckinsFor}
                venue={this.state.showingCheckinsFor}
                onClose={this.hideCheckins}
              />

              <Breadcrumbs active="Venues" />

              <Layout horizontal center spread>
                <h1>Venues</h1>

                <div className="totalVenues">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' venue'
                    : meta.total + ' venues'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Venue name"
                    />

                    <Button.Group>
                      <Button
                        active={this.state.discoverableFilter === null}
                        content="All"
                        onClick={() => this.resetDiscoverableFilter()}
                      />

                      <Button
                        active={this.state.discoverableFilter === true}
                        content="Discoverable"
                        onClick={() => this.setDiscoverableFilter(true)}
                      />

                      <Button
                        active={this.state.discoverableFilter === false}
                        content="Undiscoverable"
                        onClick={() => this.setDiscoverableFilter(false)}
                      />
                    </Button.Group>
                  </Filters.Modal>

                  <EditVenue
                    venues={venues}
                    categories={categories}
                    trigger={<Button primary content="New Venue" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : venues.length === 0 ? (
                <Message>No venues created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Name
                      </Table.HeaderCell>

                      <Table.HeaderCell width={3} textAlign="center">
                        Description
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('highlighted')}
                        sorted={getSorted('highlighted')}>
                        Highlighted
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Discoverable
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center" width={3}>
                        Location
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Categories
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        FSQ Categories
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('isLit')}
                        sorted={getSorted('isLit')}>
                        isLit
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('statistics.checkins')}
                        sorted={getSorted('statistics.checkins')}>
                        Check-ins
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the venue was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {venues.map((venue) => {
                      return (
                        <Table.Row key={venue.id}>
                          <Table.Cell textAlign="center">
                            {venue.name}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {truncateString(venue.description || '', 80)}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.highlighted ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.discoverable ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.address}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.categories.map((category) => (
                              <Label
                                key={category.id}
                                content={category.name}
                              />
                            ))}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.foursquare?.categories?.map((category) => (
                              <Label
                                key={category.id}
                                content={category.name}
                              />
                            ))}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.isLit ? 'Yes' : 'No'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.statistics?.checkins}
                            <Button
                              icon="eye"
                              onClick={() => this.showCheckins(venue)}
                            />
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(venue.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Button
                              basic
                              icon="sync"
                              title="sync"
                              onClick={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/venues/${venue.id}/sync`,
                                });
                                reload();
                              }}
                            />

                            <Link to={`/venues/${venue.id}`}>
                              <EditVenue
                                venue={venue}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Link>

                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${venue.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/venues/${venue.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
