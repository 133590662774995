import React, { useCallback } from 'react';
import { Button, Form, Header, Input, Modal, Message } from 'semantic';
import { cloneDeep, pick, pull, set, times } from 'lodash';

import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import VenuesField from 'components/form-fields/Venues';
import DateField from 'components/form-fields/Date';

const EDITABLE_FIELDS = [
  'title',
  'shortDescription',
  'longDescription',
  'fullDescription',
  'redemptionCopy',
  'venues',
  'period',
  'dailyConfig',
  'weeklyConfig',
  'monthlyConfig',
  'startsAt',
  'endsAt',
  'maxRedemptions',
];

const PERIOD_OPTIONS = [
  { key: 'NONE', text: 'Does not repeat', value: 'NONE' },
  { key: 'DAILY', text: 'Daily', value: 'DAILY' },
  { key: 'WEEKLY', text: 'Weekly', value: 'WEEKLY' },
  { key: 'MONTHLY', text: 'Monthly', value: 'MONTHLY' },
];

const CALENDAR_DAY_OF_MONTH_OPTIONS = [
  { key: '1', text: '1st', value: 1 },
  { key: '2', text: '2nd', value: 2 },
  { key: '3', text: '3rd', value: 3 },
  ...times(17, (i) => ({
    key: i + 4,
    text: `${i + 4}th`,
    value: i + 4,
  })),
  { key: '21', text: '21st', value: 21 },
  { key: '22', text: '22nd', value: 22 },
  { key: '23', text: '23rd', value: 23 },
  ...times(7, (i) => ({
    key: i + 24,
    text: `${i + 24}th`,
    value: i + 24,
  })),
  { key: '31', text: '31st', value: 31 },
];

const DAY_OF_WEEK_OPTIONS = [
  { key: '1', text: 'Sunday', value: 1 },
  { key: '2', text: 'Monday', value: 2 },
  { key: '3', text: 'Tuesday', value: 3 },
  { key: '4', text: 'Wednesday', value: 4 },
  { key: '5', text: 'Thursday', value: 5 },
  { key: '6', text: 'Friday', value: 6 },
  { key: '7', text: 'Saturday', value: 7 },
];

const DAY_OF_MONTH_OPTIONS = [
  { key: '1', text: '1st', value: 1 },
  { key: '2', text: '2nd', value: 2 },
  { key: '3', text: '3rd', value: 3 },
  { key: '4', text: '4th', value: 4 },
  { key: '5', text: '5th', value: 5 },
];

@modal
export default class EditPromo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      promo: props.promo || {},
      venues: props.venues ?? [],
    };
  }

  isUpdate() {
    return !!this.props.promo;
  }

  setField = (_evt, { name, value }) => {
    this.setState({
      promo: {
        ...this.state.promo,
        [name]: value,
      },
    });
  };

  handleInputChange = (evt) => {
    const newPromo = cloneDeep(this.state.promo);
    set(newPromo, evt.target.name, evt.target.value);
    this.setState({ promo: newPromo });
  };

  toggleWeekday = (evt) => {
    evt.preventDefault();

    const weekday = Number(evt.currentTarget.dataset.weekday);
    const newPromo = cloneDeep(this.state.promo);
    const weekdays = newPromo.weeklyConfig?.weekdays ?? [];

    if (weekdays.includes(weekday)) pull(weekdays, weekday);
    else weekdays.push(weekday);

    set(newPromo, 'weeklyConfig.weekdays', weekdays);
    this.setState({ promo: newPromo });
  };

  handleMonthModeChanged = (_evt, { value }) => {
    const newPromo = cloneDeep(this.state.promo);
    set(newPromo, 'monthlyConfig.mode', value);

    this.setState({ promo: newPromo });
  };

  handleDayOfMonthChanged = (idx, name, value) => {
    const newPromo = cloneDeep(this.state.promo);
    set(newPromo, `monthlyConfig.daysOfMonth[${idx}]${name}`, value);
    this.setState({ promo: newPromo });
  };

  handleDayOfMonthAdded = (evt) => {
    evt.preventDefault();

    const newPromo = cloneDeep(this.state.promo);
    const daysOfMonth = newPromo.monthlyConfig?.daysOfMonth ?? [];

    daysOfMonth.push({ day: 1, weekday: 6 });
    set(newPromo, 'monthlyConfig.daysOfMonth', daysOfMonth);

    this.setState({ promo: newPromo });
  };

  handleDayOfMonthRemoved = (idx) => {
    const newPromo = cloneDeep(this.state.promo);
    const daysOfMonth = newPromo.monthlyConfig?.daysOfMonth ?? [];

    daysOfMonth.splice(idx, 1);
    set(newPromo, 'monthlyConfig.daysOfMonth', daysOfMonth);

    this.setState({ promo: newPromo });
  };

  handleCalendarDayChanged = (idx, value) => {
    const newPromo = cloneDeep(this.state.promo);
    set(newPromo, `monthlyConfig.calendarDays[${idx}]`, value);
    this.setState({ promo: newPromo });
  };

  handleCalendarDayAdded = (evt) => {
    evt.preventDefault();

    const newPromo = cloneDeep(this.state.promo);
    const calendarDays = newPromo.monthlyConfig?.calendarDays ?? [];

    calendarDays.push(1);
    set(newPromo, 'monthlyConfig.calendarDays', calendarDays);

    this.setState({ promo: newPromo });
  };

  handleCalendarDayRemoved = (idx) => {
    const newPromo = cloneDeep(this.state.promo);
    const calendarDays = newPromo.monthlyConfig?.calendarDays ?? [];

    calendarDays.splice(idx, 1);
    set(newPromo, 'monthlyConfig.calendarDays', calendarDays);

    this.setState({ promo: newPromo });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { promo } = this.state;

    try {
      const body = pick(promo, EDITABLE_FIELDS);

      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/promos/${promo.id}`,
          body,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/promos',
          body,
        });
      }

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { promo, loading, error } = this.state;

    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Promo` : 'New Promo'}
        </Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-promo"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}

              <Header as="h4">
                <span style={{ color: 'blue' }}>01</span> Content
              </Header>

              <Form.Input
                required
                label="Title"
                name="title"
                placeholder="Name this promo"
                type="text"
                value={promo.title || ''}
                onChange={this.setField}
              />

              <Form.Input
                required
                label="Short description"
                name="shortDescription"
                placeholder="What is this promo?"
                type="text"
                value={promo.shortDescription || ''}
                onChange={this.setField}
              />

              <Form.TextArea
                name="fullDescription"
                label="Full description"
                placeholder="Enter the full promotion details (optional)"
                type="text"
                value={promo.fullDescription || ''}
                onChange={this.setField}
              />

              <Form.Input
                name="redemptionCopy"
                label="Promo redemption screen copy"
                placeholder="Describe how to redeem this promo (e.g.: show this screen to the bartender)"
                type="text"
                value={promo.redemptionCopy || ''}
                onChange={this.setField}
              />

              <Header as="h4">
                <span style={{ color: 'blue' }}>02</span> Participating venues
              </Header>

              <VenuesField
                name="venues"
                value={promo.venues ?? []}
                onChange={this.setField}
              />

              <Header as="h4">
                <span style={{ color: 'blue' }}>03</span> Time
              </Header>

              <Form.Dropdown
                fluid
                required
                selection
                name="period"
                label="Repeating"
                options={PERIOD_OPTIONS}
                value={promo.period}
                onChange={this.setField}
              />

              <Form.Group inline widths="equal">
                <DateField
                  required
                  time
                  name="startsAt"
                  label="Start date"
                  value={promo.startsAt}
                  onChange={this.setField}
                />

                <DateField
                  time
                  name="endsAt"
                  label="End date"
                  value={promo.endsAt}
                  onChange={this.setField}
                />
              </Form.Group>

              {promo.period === 'WEEKLY' && (
                <>
                  <Form.Field>
                    <label>Repeat on</label>
                  </Form.Field>

                  <Form.Group>
                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(2)}
                      content="M"
                      data-weekday="2"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(3)}
                      content="T"
                      data-weekday="3"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(4)}
                      content="W"
                      data-weekday="4"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(5)}
                      content="T"
                      data-weekday="5"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(6)}
                      content="F"
                      data-weekday="6"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(7)}
                      content="S"
                      data-weekday="7"
                      onClick={this.toggleWeekday}
                    />

                    <Form.Button
                      circular
                      toggle
                      active={promo.weeklyConfig?.weekdays?.includes(1)}
                      content="S"
                      data-weekday="1"
                      onClick={this.toggleWeekday}
                    />
                  </Form.Group>
                </>
              )}

              {promo.period === 'MONTHLY' && (
                <>
                  <Form.Field>
                    <label>Repeat on</label>
                  </Form.Field>

                  <Form.Group>
                    <Form.Radio
                      checked={promo.monthlyConfig?.mode === 'DAY_OF_MONTH'}
                      label="Day of the month"
                      name="monthlyConfig.mode"
                      value="DAY_OF_MONTH"
                      onChange={this.handleMonthModeChanged}
                    />
                    <Form.Radio
                      checked={promo.monthlyConfig?.mode === 'CALENDAR_DAY'}
                      label="Calendar day of the month"
                      name="monthlyConfig.mode"
                      value="CALENDAR_DAY"
                      onChange={this.handleMonthModeChanged}
                    />
                  </Form.Group>

                  {promo.monthlyConfig?.mode === 'DAY_OF_MONTH' && (
                    <>
                      {promo.monthlyConfig?.daysOfMonth?.map(
                        (dayOfMonth, idx) => (
                          <DayOfMonthControls
                            dayOfMonth={dayOfMonth}
                            idx={idx}
                            key={idx}
                            onChange={this.handleDayOfMonthChanged}
                            onRemove={this.handleDayOfMonthRemoved}
                          />
                        )
                      )}

                      <Form.Button
                        basic
                        primary
                        icon="plus"
                        onClick={this.handleDayOfMonthAdded}
                      />
                    </>
                  )}

                  {promo.monthlyConfig?.mode === 'CALENDAR_DAY' && (
                    <>
                      {promo.monthlyConfig?.calendarDays?.map(
                        (calendarDay, idx) => (
                          <CalendarDayOfMonthControls
                            day={calendarDay}
                            idx={idx}
                            key={idx}
                            onChange={this.handleCalendarDayChanged}
                            onRemove={this.handleCalendarDayRemoved}
                          />
                        )
                      )}

                      <Form.Button
                        basic
                        primary
                        icon="plus"
                        onClick={this.handleCalendarDayAdded}
                      />
                    </>
                  )}
                </>
              )}

              {['DAILY', 'WEEKLY'].includes(promo.period) && (
                <Form.Field required>
                  <label>Each promo period runs for</label>

                  <Input
                    required
                    label={{ basic: true, content: 'hours' }}
                    labelPosition="right"
                    min="1"
                    max="24"
                    name="dailyConfig.activeHours"
                    type="number"
                    value={promo.dailyConfig?.activeHours || ''}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              )}

              <Form.Input
                label={
                  <label>
                    Max number of redemptions{' '}
                    <span style={{ color: 'gray' }}>
                      (leave empty for no maximum)
                    </span>
                  </label>
                }
                placeholder="No maximum"
                min={0}
                name="maxRedemptions"
                type="number"
                value={promo.maxRedemptions || ''}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            form="edit-promo"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            type="submit"
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

function DayOfMonthControls({ idx, dayOfMonth, onChange, onRemove }) {
  const handleRemoved = useCallback(
    (evt) => {
      evt.preventDefault();
      onRemove(idx);
    },
    [idx]
  );

  const handleChanged = useCallback(
    (_evt, { name, value }) => {
      onChange(idx, name, value);
    },
    [idx]
  );

  return (
    <Form.Group key={idx}>
      <Form.Dropdown
        required
        selection
        data-idx={idx}
        name="day"
        options={DAY_OF_MONTH_OPTIONS}
        value={dayOfMonth.day}
        onChange={handleChanged}
      />

      <Form.Dropdown
        required
        selection
        data-idx={idx}
        name="weekday"
        options={DAY_OF_WEEK_OPTIONS}
        value={dayOfMonth.weekday}
        onChange={handleChanged}
      />

      <Form.Button icon="minus" data-idx={idx} onClick={handleRemoved} />
    </Form.Group>
  );
}

function CalendarDayOfMonthControls({ day, idx, onChange, onRemove }) {
  const handleRemoved = useCallback(
    (evt) => {
      evt.preventDefault();
      onRemove(idx);
    },
    [idx]
  );

  const handleChanged = useCallback(
    (_evt, { value }) => {
      onChange(idx, value);
    },
    [idx]
  );

  return (
    <Form.Group key={idx}>
      <Form.Dropdown
        required
        selection
        data-idx={idx}
        name="day"
        options={CALENDAR_DAY_OF_MONTH_OPTIONS}
        value={day}
        onChange={handleChanged}
      />

      <Form.Button icon="minus" data-idx={idx} onClick={handleRemoved} />
    </Form.Group>
  );
}
