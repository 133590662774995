import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Message, Divider, Loader, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { truncateString } from 'utils/formatting';
import EditPost from 'modals/EditPost';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import 'styles/general.css';

@screen
export default class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reported: false,
    };
  }

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/posts/search',
      body: {
        ...params,
        reported: this.state.reported,
      },
    });
  };

  toggleReported = () => {
    this.setState({ reported: !this.state.reported });
  };

  render() {
    const color = '#a099c9';

    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: posts,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Posts" />
              <Layout horizontal center spread>
                <h1>Posts</h1>

                <div className="totalPosts">
                  {meta.total == 1
                    ? meta.total + ' post'
                    : meta.total + ' posts'}
                </div>

                <Layout.Group center>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Search for exact post, venue, or username"
                    />

                    <ToggleSwitch
                      isOn={this.state.reported}
                      onColor={color}
                      onClick={() => {
                        this.toggleReported();
                      }}
                    />
                  </Filters.Modal>
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : posts.length === 0 ? (
                <Message>
                  {this.state.reported
                    ? "There aren't any reported posts yet"
                    : 'No results'}
                </Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Venue{' '}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Post{' '}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        User{' '}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Username{' '}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the post was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('commentCount')}
                        sorted={getSorted('commentCount')}>
                        Comments
                        <HelpTip
                          title="Total"
                          text="This is the total number of comments."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {' '}
                        Actions{' '}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {posts.map((post) => {
                      return (
                        <Table.Row key={post.id}>
                          <Table.Cell textAlign="center">
                            {' '}
                            {post?.venue?.name}{' '}
                          </Table.Cell>
                          <Table.Cell width={3} textAlign="center">
                            {' '}
                            {truncateString(post.content, 40)}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {post?.author?.name}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {post?.author?.nickname}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {formatDateTime(post.createdAt)}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {' '}
                            {post.commentCount}{' '}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <Link to={`/posts/${post.id}`}>
                              <EditPost
                                post={post}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Link>
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete this post?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/posts/${post.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
