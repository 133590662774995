import React from 'react';
import { pick } from 'lodash';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import UploadsField from 'components/form-fields/Uploads';
import CategoriesField from 'components/form-fields/Categories';
import AddressField from 'components/form-fields/Address';
import DateField from 'components/form-fields/Date';

@modal
export default class EditVenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      venue: props.venue || {},
      categories: props.categories ?? [],
    };
  }

  isUpdate() {
    return !!this.props.venue;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      venue: {
        ...this.state.venue,
        [name]: value,
      },
    });
  };

  setCoordinates = (evt, { value: coordinates }) => {
    this.setState({
      venue: {
        ...this.state.venue,
        location: {
          coordinates: [
            parseFloat(
              coordinates.lon ?? this.state.venue?.location?.coordinates[0]
            ),
            parseFloat(
              coordinates.lat ?? this.state.venue?.location?.coordinates[1]
            ),
          ],
        },
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { venue } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PUT',
          path: `/1/venues/${venue.id}`,
          body: pick(venue, [
            'name',
            'description',
            'discoverable',
            'categories',
            'highlighted',
            'images',
            'media',
            'address',
            'location',
            'eventStartDate',
            'eventEndDate',
          ]),
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/venues',
          body: venue,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { venue, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Venue` : 'New Venue'}
        </Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-venue"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={venue.name || ''}
                onChange={this.setField}
              />
              <Form.TextArea
                required
                name="description"
                label="Description"
                type="text"
                value={venue.description || ''}
                onChange={this.setField}
              />
              <Form.Checkbox
                name="highlighted"
                label="Highlighted"
                checked={venue.highlighted}
                onChange={this.setCheckedField}
              />
              <Form.Checkbox
                name="discoverable"
                label="Discoverable"
                checked={venue.discoverable}
                onChange={this.setCheckedField}
              />
              <Form.Group inline widths="equal">
                <DateField
                  name="eventStartDate"
                  label="Start date (event)"
                  value={venue.eventStartDate}
                  onChange={this.setField}
                />

                <DateField
                  required={!!venue.eventStartDate}
                  name="eventEndDate"
                  label="End date (event)"
                  value={venue.eventEndDate}
                  onChange={this.setField}
                />
              </Form.Group>
              <CategoriesField
                name="categories"
                value={venue.categories ?? []}
                onChange={this.setField}
              />
              <UploadsField
                name="media"
                label="Custom Media"
                maxSize={1024 * 1024 * 50}
                sortable={true}
                types={['video', 'image']}
                value={venue.media || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <AddressField
                setField={this.setField}
                value={venue?.location?.coordinates || []}
                onChange={this.setCoordinates}
                name="coordinates"
                autoComplete="yes"
                type="number"
              />
            </Form>
          </AutoFocus>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            form="edit-venue"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
